import { FC, useCallback, useMemo } from "react"
import { Col, InputNumber, Row, Typography } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { isNumber } from "lodash"

import { getSelectedServiceProviderVAT } from "../../../../../../payments/selectors"
import { Maybe } from "../../../../../../common/types"
import { setServiceProviderVAT } from "../../../../../../payments/actions"
import { getSelectedCategory } from "../../../../../categories/selectors"

const { Title } = Typography

export const ServiceProviderVatInput: FC = () => {
  const dispatch = useDispatch()

  const selectedCategory = useSelector(getSelectedCategory)
  const selectedServiceProviderVAT = useSelector(getSelectedServiceProviderVAT)

  const value = useMemo(() => {
    if (selectedCategory?.isServiceProviderVATLocked) {
      return selectedCategory.defaultServiceProviderVAT
    } else {
      return (
        selectedServiceProviderVAT ??
        selectedCategory?.defaultServiceProviderVAT
      )
    }
  }, [selectedServiceProviderVAT, selectedCategory])

  const onChange = useCallback(() => {
    const onChangeServiceProviderVAT = (value: Maybe<number | string>) => {
      if (isNumber(value)) {
        dispatch(setServiceProviderVAT(value))
      }
    }

    return selectedCategory?.isServiceProviderVATLocked
      ? undefined
      : onChangeServiceProviderVAT
  }, [dispatch, selectedCategory?.isServiceProviderVATLocked])

  return (
    <Col xs={24} lg={12}>
      <Row gutter={[24, 12]}>
        <Col xs={24}>
          <Title className="question-header-title" level={4}>
            Service Provider VAT
          </Title>
        </Col>
        <Col xs={24}>
          <InputNumber
            size="large"
            style={{ width: "100%" }}
            type="number"
            min={12}
            max={24}
            value={value}
            onChange={onChange}
          />
        </Col>
      </Row>
    </Col>
  )
}
