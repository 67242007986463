import { FC, useCallback, useMemo } from "react"
import { Col, InputNumber, Row, Typography } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { isNumber } from "lodash"

import { getSelectedCustomerVAT } from "../../../../../../payments/selectors"
import { Maybe } from "../../../../../../common/types"
import { setCustomerVAT } from "../../../../../../payments/actions"
import { getSelectedCategory } from "../../../../../categories/selectors"

const { Title } = Typography

export const CustomerVatInput: FC = () => {
  const dispatch = useDispatch()

  const selectedCategory = useSelector(getSelectedCategory)
  const selectedCustomerVAT = useSelector(getSelectedCustomerVAT)

  const onChange = useCallback((value: Maybe<number | string>) => {
    if (isNumber(value)) {
      dispatch(setCustomerVAT(value))
    }
  }, [])

  const value = useMemo(() => {
    return selectedCustomerVAT ?? selectedCategory?.defaultCustomerVAT
  }, [selectedCustomerVAT, selectedCategory])

  return (
    <Col xs={24} lg={12}>
      <Row gutter={[24, 12]}>
        <Col xs={24}>
          <Title className="question-header-title" level={4}>
            Customer VAT
          </Title>
        </Col>
        <Col xs={24}>
          <InputNumber
            size="large"
            style={{ width: "100%" }}
            type="number"
            min={12}
            max={24}
            value={value}
            onChange={onChange}
          />
        </Col>
      </Row>
    </Col>
  )
}
