import { useState } from "react"
import { OfferRequest } from "../../../../buyer/createOfferRequest/types"
import { Card, Descriptions, Button, Modal } from "antd"
import { PurchaseForm } from "./PurchaseForm"
import { updateOfferRequestPurchase } from "../../../actions"
import { useDispatch } from "react-redux"
import { PaidStatus } from "hero24-types"

interface Props {
  offerRequest: OfferRequest
}

export const FixedValues = (props: Props) => {
  const {
    id,
    data: {
      status,
      initial: {
        fixedDuration: fixedDurationFromOfferRequest = 0,
        fixedPrice: fixedPriceFromOfferRequest = 0,
        prepaid,
      },
    },
  } = props.offerRequest

  const [fixedPrice, setFixedPrice] = useState(fixedPriceFromOfferRequest)
  const [fixedDuration, setFixedDuration] = useState(
    fixedDurationFromOfferRequest,
  )

  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const dispatch = useDispatch()

  return (
    <Card style={{ background: "transparent" }} bordered={false} size="small">
      <Modal
        title="Edit purchase"
        open={showEditModal}
        onCancel={() => setShowEditModal(false)}
        footer={false}
      >
        <PurchaseForm
          onSubmit={(data) => {
            dispatch(
              updateOfferRequestPurchase(data, () => {
                const { fixedPrice = 0, fixedDuration = 0 } = data
                setShowEditModal(false)
                setFixedPrice(fixedPrice)
                setFixedDuration(fixedDuration)
              }),
            )
          }}
          purchase={{ id, fixedDuration, fixedPrice }}
        />
      </Modal>
      <Descriptions
        layout="horizontal"
        size="small"
        bordered
        style={{
          margin: 0,
          padding: 0,
          border: 0,
          background: "white",
        }}
      >
        <Descriptions.Item label="Price per hour">
          {fixedPrice} € / h
        </Descriptions.Item>
        <Descriptions.Item label="Duration">
          {fixedDuration} h
        </Descriptions.Item>
        <Descriptions.Item label="Total">
          {(fixedDuration * fixedPrice).toFixed(2)} €
        </Descriptions.Item>
      </Descriptions>
      {status === "open" && prepaid === PaidStatus.WAITING && (
        <Button
          onClick={() => setShowEditModal(true)}
          style={{ width: "100%", margin: "15px 0px" }}
        >
          Edit
        </Button>
      )}
    </Card>
  )
}
